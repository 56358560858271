import mapboxgl, {
  Expression,
  GeoJSONSource,
  IControl,
  MapLayerMouseEvent,
  MapMouseEvent,
} from "mapbox-gl";
import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { intersection, union } from "polygon-clipping";
import type { Geom } from "polygon-clipping";
import type { MultiPolygon } from "geojson";
import * as Sentry from "@sentry/browser";

import { tryToParseResponseJson } from "./fetch_util";
import {
  Coordinates,
  boundingBoxForBoundaryObj,
  calculateFieldCollectionBoundaries,
  field,
  projectResponse,
  sampleSiteFeature,
  sampleSitesResponse,
  strataFeature,
} from "./map_util";

// This incantation is just to keep the TS compiler happy:
declare global {
  interface HTMLElementEventMap {
    "turbo:submit-end": CustomEvent;
  }
}

mapboxgl.accessToken = process.env.MAP_BOX_API_KEY as string;

const DEFAULT_PADDING = 60;

const SAMPLES_SITE_COLOR_EXPRESSION: Expression = [
  "match",
  ["get", "status"],
  "primary",
  [
    "match",
    ["get", "soil_core_purpose"],
    "research",
    "purple",
    "#38BDF8", // Other
  ],
  "backup",
  "gray",
  "inaccessible",
  "red",
  "field_work_failed",
  "red",
  "recorded",
  "green",
  /* other */ "yellow",
];

const SAMPLE_SITE_SOURCE_ID = "sample-sites";
const FAT_CIRCLE_SAMPLE_SITE_LAYER_ID = "sample-sites-layer";
const TINY_CIRCLE_SAMPLE_SITE_LAYER_ID = "sample-sites-layer-tiny";

export default class extends Controller {
  static targets = ["map"];
  declare mapTarget: HTMLElement;
  declare hasMapTarget: boolean;

  static values = {
    fieldUrl: String,
    focusFieldId: Number,
    sampleSitesUrl: String,
    strataUrl: String,
    newSampleSiteUrl: String,
    enableAddingSampleSites: Boolean,
  };
  declare fieldUrlValue: string;
  declare focusFieldIdValue: number | null;
  declare sampleSitesUrlValue: string;
  declare strataUrlValue: string;
  declare newSampleSiteUrlValue: string;
  declare enableAddingSampleSitesValue: boolean;
  private addingSampleSites: boolean = false;
  private addSampleSiteControl = new AddSampleSiteControl();

  map: mapboxgl.Map | undefined;
  project: projectResponse | null = null;
  fields: field[] = [];
  popup: mapboxgl.Popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false,
    className: "z-50 filter drop-shadow-lg",
  });

  strataLayerIds: string[] = [];

  initialize() {
    this.log("initalize");
    this.loadMap();

    document.documentElement.addEventListener("turbo:click", (e: Event) => {
      this.log("handling turbo:click");
      const target = e.target as HTMLElement;

      const projectMapFieldId = target.dataset.projectMapFieldId;
      if (!projectMapFieldId) {
        this.log("no projectMapFieldId; ignoring");
        return;
      }

      const fieldId = parseInt(projectMapFieldId);

      if (fieldId === -1) {
        this.zoomToField(null);
      }

      const field = this.fields.find((f) => f.id === fieldId);
      if (field) {
        this.zoomToField(field);
      }
    });
  }

  log(msg: string) {
    console.log(`[ProjectMapsController] ${msg}`);
  }

  async loadMap() {
    if (!this.hasMapTarget) return;

    const project = await this.fetchProject();
    const fields = project?.fields || [];

    if (fields.length === 0) {
      this.log("No fields. Skipping render.");
      return;
    }

    this.project = project;
    this.fields = fields;

    this.mapTarget.innerHTML = "";

    this.log("Initializing Map");
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      bounds: calculateFieldCollectionBoundaries(fields),
      fitBoundsOptions: {
        padding: DEFAULT_PADDING,
      },
      attributionControl: false,
    });

    this.map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      })
    );

    this.map.on("load", async () => {
      // Ordering matters here - Mapbox doesn't have a concept of z-index,
      // whatever we render last shows up on top.
      this.renderFields();
      this.renderFieldsIntersection();
      await this.fetchAndRenderStrata(); // wait for this to finish before rendering POLARIS heatmaps
      this.renderPolarisHeatmaps();
      this.fetchAndRenderSampleSites();
      if (this.enableAddingSampleSitesValue) {
        this.setupAddingSampleSites();
      }
    });
  }

  async fetchProject() {
    this.log("Fetching project data");
    const response = await fetch(this.fieldUrlValue, {
      headers: {
        accept: "application/json",
      },
    });

    this.log("Parsing project JSON");
    return await tryToParseResponseJson<projectResponse>(response);
  }

  renderFields() {
    const map = this.map;
    const fields = this.fields;

    if (!map) {
      return;
    }

    this.log("attaching fields and layering");
    for (const field of fields) {
      const fieldSourceId = `field-${field.id}`;

      map.addSource(fieldSourceId, {
        type: "geojson",
        data: field.boundaries,
      });

      const fieldLayerId = `${fieldSourceId}-outline`;
      const fieldLayerOutlineId = `${fieldLayerId}-border`;

      map.addLayer({
        id: fieldLayerId,
        type: "fill",
        source: fieldSourceId,
        paint: {
          "fill-color": "#2563EB",
          "fill-opacity": 0.5,
        },
        filter: ["==", "$type", "Polygon"],
      });

      map.addLayer({
        id: fieldLayerOutlineId,
        type: "line",
        source: fieldSourceId,
        paint: {
          "line-color": "#2563EB",
          "line-width": 3,
          "line-opacity": 0.7,
        },
      });

      map.on("mouseenter", fieldLayerId, () => {
        if (this.focusFieldIdValue === field.id) {
          this.log("Ignoring mouse enter");
          return;
        }

        this.log("Mouse enter");

        if (!this.addingSampleSites) {
          map.getCanvas().style.cursor = "pointer";
        }
        const fieldBoundaries = boundingBoxForBoundaryObj(field);
        //Design: take a gander at this - it can be html with tailwind
        // also... we could use a turbo-frame like below if we think
        // we're going to want a fair amount of styling / logic
        const popupContents = `<div class="p-2">${field.name}</div>`;
        const lat = fieldBoundaries.getNorth();
        const lng = fieldBoundaries.getCenter().lng;
        this.popup.setLngLat([lng, lat]).setHTML(popupContents).addTo(map);
      });

      map.on("mouseleave", fieldLayerId, () => {
        if (this.focusFieldIdValue === field.id) {
          this.log("Ignoring mouse leave");
          return;
        }
        this.log("Mouse leave");
        if (!this.addingSampleSites) {
          map.getCanvas().style.cursor = "";
        }
        this.popup.remove();
      });

      map.on("click", fieldLayerId, async () => {
        this.log(`Click on field ${field.id}`);

        if (this.focusFieldIdValue !== field.id) {
          this.popup.remove();
          this.zoomToField(field);

          // e.g. trigger update to URL &
          // use Turbo to load right panel
          Turbo.visit(field.url);
        }
      });
    }
  }

  renderFieldsIntersection() {
    if (!this.map) return;

    const fieldsIntersection = this.calculateFieldsIntersection();
    if (fieldsIntersection == null) return;

    const intersectionSourceId = "fields-intersection";
    this.map.addSource(intersectionSourceId, {
      type: "geojson",
      data: {
        type: "Feature",
        geometry: fieldsIntersection,
        properties: {},
      },
    });
    this.map.addLayer({
      id: `${intersectionSourceId}-fill-layer`,
      source: intersectionSourceId,
      type: "fill",
      paint: {
        "fill-color": "red",
      },
    });
    this.map.addLayer({
      id: `${intersectionSourceId}-border-layer`,
      source: intersectionSourceId,
      type: "line",
      paint: {
        "line-color": "red",
        "line-width": 5,
      },
    });
  }

  calculateFieldsIntersection(): MultiPolygon | null {
    let fieldsUnion: Geom = [];
    let fieldsIntersection: Geom = [];

    for (const field of this.fields) {
      try {
        const coordinates = field.boundaries.geometry.coordinates as Geom;
        const overlap = intersection(fieldsUnion, coordinates);
        fieldsUnion = union(fieldsUnion, coordinates);
        if (overlap == null) continue;

        fieldsIntersection = union(fieldsIntersection, overlap);
      } catch (e) {
        // If this throws an exception, catch it so we don't block the rest of
        // the UI from rendering.
        Sentry.captureException(e);
      }
    }

    if (fieldsIntersection.length === 0) return null;
    return {
      type: "MultiPolygon",
      coordinates: fieldsIntersection as any[], // polygon-clipping and geojson have incompatible types
    };
  }

  zoomToField(field: field | null) {
    if (!this.map) {
      return;
    }

    if (!field) {
      this.focusFieldIdValue = null;
      const bounds = calculateFieldCollectionBoundaries(this.fields);
      this.map.fitBounds(bounds, {
        padding: DEFAULT_PADDING,
      });
      return;
    }

    this.focusFieldIdValue = field.id;

    // zoom to the field
    const fieldBoundaries = boundingBoxForBoundaryObj(field);
    this.map.fitBounds(fieldBoundaries, {
      padding: DEFAULT_PADDING,
    });
  }

  async fetchSampleSites() {
    this.log("Fetching Sample Sites");
    const sampleSitesResponse = await fetch(this.sampleSitesUrlValue, {
      headers: {
        accept: "application/json",
      },
    });

    this.log("Parsing sample sites data");
    return tryToParseResponseJson<sampleSitesResponse>(sampleSitesResponse);
  }

  async fetchAndRenderSampleSites() {
    const map = this.map;
    if (!map) {
      return;
    }

    const sampleSitesData = await this.fetchSampleSites();

    this.log("Adding source & layers");
    map.addSource(SAMPLE_SITE_SOURCE_ID, {
      type: "geojson",
      data: sampleSitesData?.sampleSites,
    });

    map.addLayer({
      id: FAT_CIRCLE_SAMPLE_SITE_LAYER_ID,
      source: SAMPLE_SITE_SOURCE_ID,
      type: "circle",
      paint: {
        "circle-color": SAMPLES_SITE_COLOR_EXPRESSION,
        "circle-radius": 6,
        "circle-stroke-color": "LightGray",
        "circle-stroke-width": 2,
      },
      minzoom: 14,
    });

    map.addLayer({
      id: TINY_CIRCLE_SAMPLE_SITE_LAYER_ID,
      source: SAMPLE_SITE_SOURCE_ID,
      type: "circle",
      paint: {
        "circle-color": SAMPLES_SITE_COLOR_EXPRESSION,
        "circle-radius": 3,
        "circle-stroke-color": "LightGray",
        "circle-stroke-width": 1,
      },
      maxzoom: 14,
    });

    map.on(
      "click",
      FAT_CIRCLE_SAMPLE_SITE_LAYER_ID,
      (e: MapLayerMouseEvent) => {
        if (this.addingSampleSites) return;

        this.log(`Click on sample site layer`);
        const feature = e.features?.[0] as sampleSiteFeature | undefined;
        if (feature) {
          this.popup.remove();
          const popupContents = `<turbo-frame id="sample-site-popup" src="${feature.properties.url}"><div class="p-2">Loading...</div></turbo-frame>`;
          this.popup
            .setLngLat(feature.geometry.coordinates)
            .setHTML(popupContents)
            .addTo(map);
        }
      }
    );

    // if the page loaded with a field, zoom to it
    if (this.focusFieldIdValue) {
      const field = this.fields.find((f) => f.id === this.focusFieldIdValue);
      if (field) {
        this.zoomToField(field);
      }
    }
  }

  async fetchAndRenderStrata() {
    const map = this.map;
    if (!map) {
      return;
    }

    this.log("Fetching Strata");
    const strataResponse = await fetch(this.strataUrlValue, {
      headers: {
        accept: "application/json",
      },
    });

    this.log("Parsing strata data");
    const strataData = await tryToParseResponseJson<strataFeature[]>(
      strataResponse
    );

    if (!strataData || strataData.length === 0) {
      this.log("No strata. Skipping render.");
      return;
    }

    let opacityIndex = 0;
    const opacityArray = [0.45, 0.45, 0.45, 0.45, 0.45];
    const colorArray = ["#00807d", "#00bab5", "#d2f7ed", "#50a7da", "#00619b"];

    for (const stratum of strataData) {
      const stratumSouceId = `statum-source-${stratum.id}`;
      map.addSource(stratumSouceId, {
        type: "geojson",
        data: stratum.boundaries,
      });

      const opacity = opacityArray[opacityIndex];
      const color = colorArray[opacityIndex];

      const stratumLayerId = `stratum-layer-${stratum.id}`;
      map.addLayer({
        id: stratumLayerId,
        source: stratumSouceId,
        type: "fill",
        paint: {
          "fill-color": color,
          "fill-opacity": opacity,
        },
        layout: {
          visibility: "visible",
        },
      });

      this.strataLayerIds.push(stratumLayerId);

      const stratumBorderId = `stratum-border-${stratum.id}`;
      map.addLayer({
        id: stratumBorderId,
        source: stratumSouceId,
        type: "line",
        paint: {
          "line-color": color,
          "line-width": 1,
          "line-opacity": 0.8,
        },
        layout: {
          visibility: "visible",
        },
      });
      this.strataLayerIds.push(stratumBorderId);

      // to do a wrapping index
      opacityIndex = (opacityIndex + 1) % opacityArray.length;
    }
  }

  renderPolarisHeatmaps() {
    const map = this.map;
    const project = this.project;
    const fields = this.fields;

    if (!map || !project || fields.length === 0) return;

    const boundingBox = calculateFieldCollectionBoundaries(fields);
    const coordinates = [
      boundingBox.getNorthWest().toArray() as Coordinates,
      boundingBox.getNorthEast().toArray() as Coordinates,
      boundingBox.getSouthEast().toArray() as Coordinates,
      boundingBox.getSouthWest().toArray() as Coordinates,
    ];

    const layerOptions = {
      layout: { visibility: "none" }, // hide on page load
      paint: {
        "raster-opacity": 0.75,
        "raster-resampling": "nearest", // don't smooth pixel boundaries
      },
    };

    if (project.polaris_stock_mean_image_url) {
      this.renderImage({
        sourceId: "polaris-mean",
        layerId: "polaris-mean-layer",
        url: project.polaris_stock_mean_image_url,
        coordinates,
        ...layerOptions,
      });
    }

    if (project.polaris_stock_sd_image_url) {
      this.renderImage({
        sourceId: "polaris-sd",
        layerId: "polaris-sd-layer",
        url: project.polaris_stock_sd_image_url,
        coordinates,
        ...layerOptions,
      });
    }
  }

  renderImage({
    sourceId,
    layerId,
    url,
    coordinates,
    ...layerOptions
  }: {
    sourceId: string;
    layerId: string;
    url: string;
    coordinates: Coordinates[];
    [key: string]: any;
  }) {
    const map = this.map;
    if (!map) return;

    map.addSource(sourceId, { type: "image", url, coordinates });
    map.addLayer({
      type: "raster",
      id: layerId,
      source: sourceId,
      ...layerOptions,
    });
  }

  toggleStrata(e: Event) {
    this.log("toggling strata visibility");

    const checkbox = e.target as HTMLInputElement;
    this.strataLayerIds.forEach((layerId) => {
      if (checkbox.checked) {
        this.showLayer(layerId);
      } else {
        this.hideLayer(layerId);
      }
    });
  }

  showPolarisMeanHeapmap(e: Event) {
    this.hideLayer("polaris-sd-layer");
    this.showLayer("polaris-mean-layer");
  }

  showPolarisSdHeatmap(e: Event) {
    this.hideLayer("polaris-mean-layer");
    this.showLayer("polaris-sd-layer");
  }

  hidePolarisHeatmaps(e: Event) {
    this.hideLayer("polaris-mean-layer");
    this.hideLayer("polaris-sd-layer");
  }

  showLayer(layerId: string) {
    const map = this.map;
    if (!map) return;

    map.setLayoutProperty(layerId, "visibility", "visible");
  }

  hideLayer(layerId: string) {
    const map = this.map;
    if (!map) return;

    map.setLayoutProperty(layerId, "visibility", "none");
  }

  setupAddingSampleSites() {
    const map = this.map;
    if (!map) return;

    // Add control to map for adding sample sites. When clicked, this button
    // switches the map to "adding sample site" mode.
    this.addSampleSiteControl.onClick(() => this.toggleAddingSampleSitesMode());
    map.addControl(this.addSampleSiteControl, "top-right");

    // When in "adding sample site" mode, click on the map to display a popup
    // with a new sample site form.
    map.on("click", (e: MapMouseEvent) =>
      this.showNewSampleSitePopup(e.lngLat)
    );

    // When a sample site is created or destroyed, refresh the map:
    document.documentElement.addEventListener(
      "turbo:submit-end",
      (e: CustomEvent) => {
        if (!e.detail.success) return;

        this.refreshSampleSites();

        if (this.addingSampleSites) {
          this.toggleAddingSampleSitesMode();
        } else {
          this.popup.remove();
        }
      }
    );
  }

  toggleAddingSampleSitesMode() {
    const map = this.map;
    if (!map) return;

    // Toggle "adding sample site" mode:
    this.addingSampleSites = !this.addingSampleSites;
    this.addSampleSiteControl.setActiveState(this.addingSampleSites);

    // Remove any existing popup to clear the map:
    if (this.addingSampleSites) {
      this.popup.remove();
    }

    // Change the cursor to give a visual cue that we're in "adding sample site" mode:
    map.getCanvas().style.cursor = this.addingSampleSites ? "crosshair" : "";
  }

  showNewSampleSitePopup({ lng, lat }: { lng: number; lat: number }) {
    const map = this.map;
    if (!map) return;

    if (!this.addingSampleSites) return;

    // Include the click location as url params so the backend knows where
    // the new sample site should go:
    const newSampleSiteUrl = new URL(
      this.newSampleSiteUrlValue,
      window.location.origin
    );
    const searchParams = new URLSearchParams(newSampleSiteUrl.search);
    searchParams.set("lng", lng.toString());
    searchParams.set("lat", lat.toString());
    newSampleSiteUrl.search = searchParams.toString();

    this.popup
      .setLngLat([lng, lat])
      .setHTML(
        `<turbo-frame id="sample-site-popup" src="${newSampleSiteUrl}"><div class="p-2">Loading...</div></turbo-frame>`
      )
      .addTo(map);
  }

  async refreshSampleSites() {
    const map = this.map;
    if (!map) return;

    const sampleSitesData = await this.fetchSampleSites();
    if (!sampleSitesData) return;

    const source = map.getSource(SAMPLE_SITE_SOURCE_ID) as GeoJSONSource;
    source.setData(sampleSitesData.sampleSites);
  }
}

class AddSampleSiteControl implements IControl {
  private container: HTMLElement;
  private button: HTMLButtonElement;
  private active: boolean = false;

  constructor() {
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    this.button = document.createElement("button");
    this.button.className = "backdrop-filter backdrop-invert-0 hint--left";
    this.button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 m-auto">
      <path fill-rule="evenodd" d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" clip-rule="evenodd" />
    </svg>`;
    this.button.setAttribute("aria-label", "Add R&D Sample Site");

    this.container.appendChild(this.button);
    this.onClick((active) => {
      this.active = !active;
      this.displayActiveState();
    });
  }

  onAdd() {
    return this.container;
  }

  onRemove() {
    this.container.parentNode!.removeChild(this.container);
  }

  onClick(handler: (e: Event) => void) {
    this.button.addEventListener("click", handler);
  }

  setActiveState(active: boolean) {
    this.active = active;
    this.displayActiveState();
  }

  displayActiveState() {
    if (this.active) {
      this.button.classList.replace("backdrop-invert-0", "backdrop-invert");
    } else {
      this.button.classList.replace("backdrop-invert", "backdrop-invert-0");
    }
  }
}
