import * as Sentry from "@sentry/browser";

import * as Rails from "@rails/ujs";
import { restoreRailsDisableWith } from "../util";
import "@hotwired/turbo-rails";
import * as Turbo from "@hotwired/turbo";

Sentry.init({
  dsn: process.env.SENTRY_DSN,

  environment: process.env.RAILS_ENV,

  release: process.env.HEROKU_SLUG_COMMIT,

  integrations: [Sentry.browserTracingIntegration()],

  tracesSampleRate: 1.0,

  ignoreErrors: ["AbortError:"],
});

declare global {
  var USER_EMAIL: string | null;
}
if (window.USER_EMAIL) Sentry.setUser({ email: window.USER_EMAIL });

Turbo.setProgressBarDelay(200);

Rails.start();
restoreRailsDisableWith();

require("stylesheets/application.scss");
require.context("images", true);
import "controllers";
import "direct_uploads";

// @ts-ignore
import LocalTime from "local-time";
LocalTime.start();
