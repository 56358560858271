import * as mapboxgl from "mapbox-gl";
import {
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  MapMouseEvent,
} from "mapbox-gl";
import { Controller } from "@hotwired/stimulus";

import { tryToParseResponseJson } from "./fetch_util";
import {
  boundingBoxForBoundaryObj,
  calculateFieldCollectionBoundaries,
  field,
  projectResponse,
  sampleSiteFeature,
  sampleSitesResponse,
  strataFeature,
  StimulusEvent,
} from "./map_util";

const DEFAULT_PADDING = 60;

const BASE_COLOR = "#2563EB";
const HOVER_COLOR = "#83a5f4";
const SELECTED_COLOR = "white";
const SAMPLE_SITE_BASE_COLOR = "#38BDF8";
const SAMPLE_BASE_RADIUS_AND_ZOOM_STOPS = [
  "interpolate",
  ["linear"],
  ["zoom"],
  // zoom is 8 (or less) -> circle radius will be 1px
  8,
  1,
  // zoom is 16 (or greater) -> circle radius will be 6px
  16,
  6,
] as mapboxgl.Expression;
const SAMPLE_HOVER_OUTLINE_RADIUS_AND_ZOOM_STOPS = [
  "interpolate",
  ["linear"],
  ["zoom"],
  8,
  2, // zoom is 8 (or less) -> circle radius will be 2px
  16,
  10, // zoom is 16 (or greater) -> circle radius will be 10px
];

class StrataColorSchema {
  index: number;

  constructor(strata: strataFeature[]) {
    this.index = 0;
  }

  colorFor(stratum: strataFeature): [string, number] {
    const opacityArray = [0.25, 0.45, 0.65, 0.85];

    const currentIndex = this.index;
    this.index += 1;
    this.index = this.index % opacityArray.length;

    return [BASE_COLOR, opacityArray[currentIndex]];
  }
}

export default class extends Controller {
  static targets = ["map"];
  declare mapTarget: HTMLElement;

  static values = {
    projectUrl: String,
    sampleSitesUrl: String,
    strataUrl: String,
    stage: String,
  };

  declare projectUrlValue: string;
  declare sampleSitesUrlValue: string;
  declare strataUrlValue: string;

  map: mapboxgl.Map | undefined;
  popup: mapboxgl.Popup = new mapboxgl.Popup({
    closeButton: true,
    closeOnClick: false,
    className: "z-50", // the strata/field box uses z-40
  });

  fields: field[] = [];
  strataLayerIds: string[] = [];
  sampleSites: sampleSiteFeature[] = [];
  initialMapDescriptionUrl: string | null = null;
  hoveredSampleSiteId: string = "";
  currentlySelectedFieldId: string = "";

  initialize() {
    this.log("initialize");
    this.loadMap();
  }

  async loadMap() {
    const fields = await this.fetchProjectData();

    if (fields.length === 0) {
      this.log("No fields. Skipping render.");
      return;
    }

    this.fields = fields;

    this.mapTarget.innerHTML = "";

    this.log("Initializing Map");
    this.map = new mapboxgl.Map({
      container: this.mapTarget,
      style: "mapbox://styles/mapbox/satellite-streets-v11",
      bounds: calculateFieldCollectionBoundaries(fields),
      fitBoundsOptions: {
        padding: DEFAULT_PADDING,
      },
      attributionControl: false,
    });

    this.map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
      })
    );

    this.map.on("load", () => {
      this.renderFields();
      this.fetchAndRenderSampleSites();
      this.fetchAndRenderStrata();
    });
  }

  async fetchProjectData() {
    this.log("Fetching project data");
    const response = await fetch(this.projectUrlValue, {
      headers: {
        accept: "application/json",
      },
    });

    this.log("Parsing project JSON");
    const responseJson = await tryToParseResponseJson<projectResponse>(
      response
    );
    return responseJson?.fields || [];
  }

  renderFields() {
    const map = this.map;
    const fields = this.fields;

    if (!map) {
      return;
    }

    this.log("attaching fields and layering");
    for (const field of fields) {
      const fieldSourceId = `field-${field.id}`;
      map.addSource(fieldSourceId, {
        type: "geojson",
        data: field.boundaries,
      });

      const fieldLayerId = `${fieldSourceId}-outline`;
      const fieldLayerOutlineId = `${fieldLayerId}-border`;

      map.addLayer({
        id: fieldLayerId,
        type: "fill",
        source: fieldSourceId,
        paint: {
          "fill-color": BASE_COLOR,
          "fill-opacity": 0.3,
        },
        filter: ["==", "$type", "Polygon"],
      });

      map.addLayer({
        id: fieldLayerOutlineId,
        type: "line",
        source: fieldSourceId,
        paint: {
          "line-color": BASE_COLOR,
          "line-width": 3,
          "line-opacity": 0.7,
        },
      });

      map.on("mouseenter", fieldLayerId, () => {
        this.log("Mouse enter field");

        if (this.strataVisible() || this.fields.length === 1) {
          this.log("Ignoring mouse field enter");
          return;
        }

        this.updateMapDescriptionSrc(field.url);
        if (!this.fieldCurrentlySelected(field)) {
          map.setPaintProperty(fieldLayerOutlineId, "line-color", HOVER_COLOR);
        }
        map.moveLayer(fieldLayerOutlineId);
      });

      map.on("click", fieldLayerId, (e: MapMouseEvent) => {
        if (
          map
            .queryRenderedFeatures(e.point)
            .some((feature) => feature.source === "sample-sites")
        ) {
          // This event handler fires for clicks on sample sites as well as
          // fields. Skip if the user clicked on a sample site.
          return;
        }

        this.log(`Click on field ${field.id}`);

        if (field) {
          this.resetFieldOutlineColors(fields);
          this.zoomToField(field);
          this.updateSidebar(field);
        }
        map.setPaintProperty(fieldLayerOutlineId, "line-color", SELECTED_COLOR);
      });

      map.on("mouseleave", fieldLayerId, (e: MapMouseEvent) => {
        this.log("Mouse leave field");
        if (!this.strataVisible() && !this.fieldCurrentlySelected(field)) {
          map.setPaintProperty(fieldLayerOutlineId, "line-color", BASE_COLOR);
        }

        this.conditionallyRestoreMapDescription(e);
      });

      if (this.fieldCurrentlySelected(field)) {
        map.setPaintProperty(fieldLayerOutlineId, "line-color", SELECTED_COLOR);
      }
    }
  }

  async fetchAndRenderSampleSites() {
    const map = this.map;
    if (!map) {
      return;
    }

    this.log("Fetching Sample Sites");
    const sampleSitesResponse = await fetch(this.sampleSitesUrlValue, {
      headers: {
        accept: "application/json",
      },
    });

    this.log("Parsing sample sites data");
    const sampleSitesData = await tryToParseResponseJson<sampleSitesResponse>(
      sampleSitesResponse
    );

    if (!sampleSitesData || sampleSitesData.sampleSites.features.length === 0) {
      this.log("No sample sites. Skipping render.");
      return;
    }

    const sampleSites = sampleSitesData.sampleSites.features;
    for (const sampleSite of sampleSites) {
      const sampleSiteSourceId = `sampleSite-${sampleSite.properties.id}`;
      const outlineOfsampleSiteSourceId = `outlineOfsampleSite-${sampleSite.properties.id}`;

      this.log("Adding source & layers");
      map.addSource(sampleSiteSourceId, {
        type: "geojson",
        data: sampleSite,
      });

      this.sampleSites.push(sampleSite);

      map.addLayer({
        id: outlineOfsampleSiteSourceId,
        source: sampleSiteSourceId,
        type: "circle",
        paint: {
          "circle-color": "white",
          "circle-radius": SAMPLE_BASE_RADIUS_AND_ZOOM_STOPS,
        },
      });

      map.addLayer({
        id: sampleSiteSourceId,
        source: sampleSiteSourceId,
        type: "circle",
        paint: {
          "circle-color": SAMPLE_SITE_BASE_COLOR,
          "circle-radius": SAMPLE_BASE_RADIUS_AND_ZOOM_STOPS,
        },
      });

      map.on(
        "click",
        [sampleSiteSourceId, outlineOfsampleSiteSourceId],
        (e: MapLayerMouseEvent) => {
          this.log(`Click on sample site layer`);
          const feature = e.features?.[0] as unknown as
            | sampleSiteFeature
            | undefined;
          if (feature) {
            this.popup.remove();
            const popupContents = `<turbo-frame id="sample-site-popup" src="${feature.properties.url}"><div class="p-2">Loading...</div></turbo-frame>`;
            this.popup
              .setLngLat(feature.geometry.coordinates)
              .setHTML(popupContents)
              .addTo(map);
          }

          this.resetSampleSiteColors(this.sampleSites);
        }
      );

      map.on(
        "mouseover",
        [sampleSiteSourceId, outlineOfsampleSiteSourceId],
        (e: MapLayerMouseEvent) => {
          this.log("Sample site mouseover");
          const feature = e.features?.[0] as MapboxGeoJSONFeature;
          const sampleSiteId = feature?.properties?.id.toString();
          map.getCanvas().style.cursor = "pointer";
          this.toggleSampleSiteHoverOn(sampleSiteId);
          this.hoveredSampleSiteId = sampleSiteId;
        }
      );

      map.on(
        "mouseleave",
        [sampleSiteSourceId, outlineOfsampleSiteSourceId],
        () => {
          this.log("Sample site mouseleave");

          if (
            map.getPaintProperty(sampleSiteSourceId, "circle-color") !==
            BASE_COLOR
          ) {
            this.toggleSampleSiteHoverOff(this.hoveredSampleSiteId);
            this.hoveredSampleSiteId = "";
          }
          map.getCanvas().style.cursor = "";
        }
      );
    }
  }

  async fetchAndRenderStrata() {
    const map = this.map;
    if (!map) {
      return;
    }

    this.log("Fetching Strata");
    const strataResponse = await fetch(this.strataUrlValue, {
      headers: {
        accept: "application/json",
      },
    });

    this.log("Parsing strata data");
    const strataData = await tryToParseResponseJson<strataFeature[]>(
      strataResponse
    );

    if (!strataData || strataData.length === 0) {
      this.log("No strata. Skipping render.");
      return;
    }

    let schema = new StrataColorSchema(strataData);

    for (const stratum of strataData) {
      const stratumSouceId = `statum-source-${stratum.id}`;
      map.addSource(stratumSouceId, {
        type: "geojson",
        data: stratum.boundaries,
      });

      const [fillColor, opacity] = schema.colorFor(stratum);

      const stratumLayerId = `stratum-layer-${stratum.id}`;
      map.addLayer({
        id: stratumLayerId,
        source: stratumSouceId,
        type: "fill",
        paint: {
          "fill-color": fillColor,
          "fill-opacity": opacity,
        },
        layout: {
          visibility: "none",
        },
      });

      this.strataLayerIds.push(stratumLayerId);

      const stratumBorderId = `stratum-border-${stratum.id}`;
      map.addLayer({
        id: stratumBorderId,
        source: stratumSouceId,
        type: "line",
        paint: {
          "line-color": BASE_COLOR,
          "line-width": 1,
          "line-opacity": 0.7,
        },
        layout: {
          visibility: "none",
        },
      });
      this.strataLayerIds.push(stratumBorderId);

      map.on("mouseenter", stratumLayerId, () => {
        this.log("Mouse enter strata");
        this.updateMapDescriptionSrc(stratum.url);

        map.setPaintProperty(stratumBorderId, "line-color", HOVER_COLOR);
        map.moveLayer(stratumBorderId);
      });

      map.on("mouseleave", stratumLayerId, () => {
        this.log("Mouse leave strata");
        map.setPaintProperty(stratumBorderId, "line-color", BASE_COLOR);
      });
    }
  }

  toggleStrata(e: Event) {
    this.log("toggling strata visibility");

    const checkbox = e.target as HTMLInputElement;
    this.strataLayerIds.forEach((layerId) => {
      if (!this.map) {
        return;
      }
      this.map.setLayoutProperty(
        layerId,
        "visibility",
        checkbox.checked ? "visible" : "none"
      );
    });
  }

  strataVisible(): boolean {
    if (!this.map) {
      return false;
    }
    for (const layerId of this.strataLayerIds) {
      const visibility = this.map.getLayoutProperty(layerId, "visibility");
      if (visibility === "visible") {
        return true;
      }
    }
    return false;
  }

  updateMapDescriptionSrc(src: string) {
    const mapElement = document.getElementById("map-description");
    if (mapElement == null) {
      return;
    }

    if (this.initialMapDescriptionUrl === null) {
      this.initialMapDescriptionUrl = mapElement.getAttribute("src");
    }

    mapElement.setAttribute("src", src);
  }

  conditionallyRestoreMapDescription(e: MapMouseEvent) {
    if (this.initialMapDescriptionUrl === null) {
      return;
    }

    const featuresCurrentlyUndercursor = this.map?.queryRenderedFeatures(
      e.point
    );

    if (!featuresCurrentlyUndercursor) {
      this.updateMapDescriptionSrc(this.initialMapDescriptionUrl);
      return;
    }

    let shouldRestore = true;

    for (let i = 0; i < featuresCurrentlyUndercursor.length; i++) {
      const feature = featuresCurrentlyUndercursor[i];
      // the only layers with IDs are ones we've added
      if (feature.id !== undefined) {
        shouldRestore = false;
      }
    }

    if (shouldRestore) {
      this.updateMapDescriptionSrc(this.initialMapDescriptionUrl);
    }
  }

  zoomToField(fieldOrEvent: field | StimulusEvent | null) {
    if (!this.map) {
      return;
    }

    let field: field | null | undefined;
    if (fieldOrEvent instanceof Event) {
      const { fieldId } = fieldOrEvent.params;
      field =
        fieldId == null ? null : this.fields.find(({ id }) => id === fieldId);
    } else {
      field = fieldOrEvent;
    }

    this.popup.remove();

    if (!field) {
      this.resetFieldOutlineColors(this.fields);
      const bounds = calculateFieldCollectionBoundaries(this.fields);
      this.map.fitBounds(bounds, {
        padding: DEFAULT_PADDING,
      });
      this.currentlySelectedFieldId = "";
      return;
    }

    this.resetFieldOutlineColors(this.fields);
    this.map.setPaintProperty(
      `field-${field.id}-outline-border`,
      "line-color",
      SELECTED_COLOR
    );
    this.currentlySelectedFieldId = field.id.toString();
    const fieldBoundaries = boundingBoxForBoundaryObj(field);
    this.map.fitBounds(fieldBoundaries, {
      padding: DEFAULT_PADDING,
    });
  }

  handleClickSampleSiteInSidebar(e: Event) {
    const target = e.target as HTMLElement;

    const deliveryMapSampleSiteId = target.dataset.deliveryMapSampleSiteId;
    this.log(`clicked sampleSite id ${deliveryMapSampleSiteId}`);

    if (!deliveryMapSampleSiteId) {
      this.log("no deliveryMapSampleSiteId; ignoring");
      return;
    }

    if (!this.map) {
      return;
    }

    const deliveryMapParentFieldId = target.dataset.deliveryMapParentFieldId;
    if (deliveryMapParentFieldId) {
      const fieldId = parseInt(deliveryMapParentFieldId);
      const field = this.fields.find((f) => f.id === fieldId) || null;
      this.zoomToField(field);
    }

    this.resetSampleSiteColors(this.sampleSites);

    const sampleSite = this.sampleSites.find(
      (s) => s.properties.id === deliveryMapSampleSiteId
    );
    const popupContents = `<turbo-frame id="sample-site-popup" src="${target.dataset.sampleSiteUrl}">Loading...</turbo-frame>`;
    const coordinates = sampleSite?.geometry.coordinates;
    if (coordinates) {
      this.popup.setLngLat(coordinates).setHTML(popupContents).addTo(this.map);

      this.popup.on("close", () => {
        this.resetSampleSiteColors(this.sampleSites);
      });
    }
  }

  handleMouseoverSampleSiteInSidebar(e: Event) {
    const target = e.target as HTMLElement;
    const sampleSiteId = target.dataset.deliveryMapSampleSiteId;

    if (!sampleSiteId) {
      return;
    }

    this.toggleSampleSiteHoverOn(sampleSiteId);
  }

  handleMouseoutSampleSiteInSidebar(e: Event) {
    const target = e.target as HTMLElement;
    const sampleSiteId = target.dataset.deliveryMapSampleSiteId;

    if (!sampleSiteId) {
      return;
    }

    this.toggleSampleSiteHoverOff(sampleSiteId);
  }

  log(msg: string) {
    console.log(`[DeliveryMapsContorller] ${msg}`);
  }

  dismissPopup(e: Event) {
    this.popup.remove();
  }

  fieldCurrentlySelected(field: field): boolean {
    return this.currentlySelectedFieldId == field.id.toString();
  }

  resetFieldOutlineColors(fields: field[]) {
    if (!this.map) {
      return;
    }

    for (const field of fields) {
      this.map.setPaintProperty(
        `field-${field.id}-outline-border`,
        "line-color",
        BASE_COLOR
      );
    }
  }

  resetSampleSiteColors(sampleSites: sampleSiteFeature[]) {
    if (!this.map) {
      return;
    }

    for (const sampleSite of sampleSites) {
      const sampleSiteId = sampleSite.properties.id;
      this.map.setPaintProperty(
        `sampleSite-${sampleSiteId}`,
        "circle-color",
        SAMPLE_SITE_BASE_COLOR
      );
      this.map.setPaintProperty(
        `outlineOfsampleSite-${sampleSiteId}`,
        "circle-radius",
        SAMPLE_BASE_RADIUS_AND_ZOOM_STOPS
      );
    }
  }

  toggleSampleSiteHoverOn(sampleSiteId: string) {
    if (!this.map || !sampleSiteId) {
      return;
    }

    const outlineOfsampleSiteLayerId = `outlineOfsampleSite-${sampleSiteId}`;

    this.map.setPaintProperty(
      outlineOfsampleSiteLayerId,
      "circle-radius",
      SAMPLE_HOVER_OUTLINE_RADIUS_AND_ZOOM_STOPS
    );
  }

  toggleSampleSiteHoverOff(sampleSiteId: string) {
    if (!this.map || !sampleSiteId) {
      return;
    }

    const outlineOfsampleSiteLayerId = `outlineOfsampleSite-${sampleSiteId}`;

    this.map.setPaintProperty(
      outlineOfsampleSiteLayerId,
      "circle-radius",
      SAMPLE_BASE_RADIUS_AND_ZOOM_STOPS
    );
  }

  updateSidebar(field: field) {
    const sidebarField = document.getElementById("delivery-field-table");
    if (sidebarField == null) {
      return;
    }
    sidebarField.setAttribute("src", `?field_id=${field.id}`);
  }
}
