import * as mapboxgl from "mapbox-gl";
import type { Feature, MultiPolygon, Polygon } from "geojson";

export type Boundary = Feature<Polygon | MultiPolygon>;

export interface StimulusEvent extends Event {
  params: { [key: string]: number | string | object | boolean };
}

export type Coordinates = [number, number];

export type field = {
  id: number;
  name: string;
  url: string;
  boundaries: Boundary;
};

export type projectResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  fields: field[];
  polaris_stock_mean_image_url: string | null;
  polaris_stock_sd_image_url: string | null;
  bounding_box: [Coordinates, Coordinates, Coordinates, Coordinates];
};

export type sampleSitesResponse = {
  sampleSites: {
    type: "FeatureCollection";
    features: sampleSiteFeature[];
  };
};

export type sampleSiteFeature = {
  type: "Feature";
  properties: {
    id: string;
    url: string;
  };
  geometry: {
    type: "Point";
    coordinates: Coordinates;
  };
};

export type strataFeature = {
  id: number;
  created_at: string;
  updated_at: string;
  boundaries: Boundary;
  url: string;
  area_in_meters: number | null;
};

export function boundingBoxForBoundaryObj(boundaryObj: {
  boundaries: Boundary;
}): mapboxgl.LngLatBounds {
  let bounds = new mapboxgl.LngLatBounds();
  const fieldPolygon = boundaryObj.boundaries;
  const geometry = fieldPolygon.geometry;

  if (geometry.type === "Polygon") {
    geometry.coordinates.forEach((positionArray) => {
      positionArray.forEach((position) => {
        // the `as` is necessary because of a type disagreement
        // between the `geojson` and `mapbox-gl` libraries
        bounds.extend(position as Coordinates);
      });
    });
  }

  if (geometry.type === "MultiPolygon") {
    geometry.coordinates.forEach((polygon) => {
      polygon.forEach((positionArray) => {
        positionArray.forEach((position) => {
          // the `as` is necessary because of a type disagreement
          // between the `geojson` and `mapbox-gl` libraries
          bounds.extend(position as Coordinates);
        });
      });
    });
  }

  return bounds;
}

export function calculateFieldCollectionBoundaries(
  fields: field[]
): mapboxgl.LngLatBounds {
  let bounds = new mapboxgl.LngLatBounds();
  fields.forEach((field) => {
    const fieldBoundingBox = boundingBoxForBoundaryObj(field);
    bounds.extend(fieldBoundingBox);
  });

  return bounds;
}
