import { Controller } from "@hotwired/stimulus";
import * as Choices from "choices.js";

export default class extends Controller {
  static targets = [
    "labTestsSelect",
    "subSampleIdHiddenInput",
    "destroyHiddenInput",
  ];
  declare labTestsSelectTarget: HTMLSelectElement;
  declare subSampleIdHiddenInputTarget: HTMLInputElement;
  declare destroyHiddenInputTarget: HTMLInputElement;

  connect() {
    // @ts-ignore
    new Choices(this.labTestsSelectTarget, {
      allowHTML: false,
      removeItemButton: true,
    });
  }

  delete() {
    // If the record is persisted, mark it for deletion and hide the row.
    // Otherwise just delete the row:
    if (this.subSampleIdHiddenInputTarget.value) {
      this.destroyHiddenInputTarget.value = "true";
      this.element.classList.add("hidden");
    } else {
      this.element.remove();
    }
  }
}
