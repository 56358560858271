import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "toggle", "hiddenInput"];
  declare buttonTarget: HTMLButtonElement;
  declare toggleTarget: HTMLElement;
  declare hiddenInputTarget: HTMLInputElement;

  onColor = "bg-indigo-600";
  offColor = "bg-gray-200";

  onTranslate = "translate-x-5";
  offTranslate = "translate-x-0";

  toggle(event: Event) {
    event.preventDefault();

    const isOn = this.hiddenInputTarget.value === "true";
    if (isOn) {
      this.turnOff();
    } else {
      this.turnOn();
    }
  }

  turnOn() {
    this.buttonTarget.classList.remove(this.offColor);
    this.buttonTarget.classList.add(this.onColor);

    this.toggleTarget.classList.remove(this.offTranslate);
    this.toggleTarget.classList.add(this.onTranslate);

    this.hiddenInputTarget.value = "true";

    this.dispatch("on");
  }

  turnOff() {
    this.buttonTarget.classList.remove(this.onColor);
    this.buttonTarget.classList.add(this.offColor);

    this.toggleTarget.classList.remove(this.onTranslate);
    this.toggleTarget.classList.add(this.offTranslate);

    this.hiddenInputTarget.value = "false";

    this.dispatch("off");
  }
}
