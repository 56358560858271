import * as ActiveStorage from "@rails/activestorage";
import * as Sentry from "@sentry/browser";

// Mostly copy-pasta from https://guides.rubyonrails.org/active_storage_overview.html#direct-uploads

type DirectUploadEvent = Event & {
  detail?: {
    id: string;
    file: File;
    progress?: number;
    error?: string;
  };
};

addEventListener("direct-upload:initialize", (event: DirectUploadEvent) => {
  const { target, detail } = event;
  if (!(target instanceof HTMLElement)) return;
  if (!detail) return;

  const { id, file } = detail;
  target.insertAdjacentHTML(
    "beforebegin",
    `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
    </div>
  `
  );

  const filename = target.previousElementSibling?.querySelector(
    `.direct-upload__filename`
  );
  if (filename) filename.textContent = file.name;
});

addEventListener("direct-upload:start", (event: DirectUploadEvent) => {
  const { detail } = event;
  if (!detail) return;

  const { id } = detail;
  const element = document.getElementById(`direct-upload-${id}`);
  if (!element) return;

  element.classList.remove("direct-upload--pending");
});

addEventListener("direct-upload:progress", (event: DirectUploadEvent) => {
  const { detail } = event;
  if (!detail) return;

  const { id, progress } = detail;
  const progressElement = document.getElementById(
    `direct-upload-progress-${id}`
  );
  if (!progressElement) return;

  progressElement.style.width = `${progress}%`;
});

addEventListener("direct-upload:error", (event: DirectUploadEvent) => {
  event.preventDefault();

  const { detail } = event;
  Sentry.captureMessage(detail?.error || "Direct upload error", {
    contexts: {
      file: {
        name: detail?.file.name,
        size: detail?.file.size,
        type: detail?.file.type,
      },
    },
  });

  if (!detail) return;

  const { id, error } = detail;
  const element = document.getElementById(`direct-upload-${id}`);
  if (!element) return;

  element.classList.add("direct-upload--error");
  if (error) element.setAttribute("title", error);
});

addEventListener("direct-upload:end", (event: DirectUploadEvent) => {
  const { detail } = event;
  if (!detail) return;

  const { id } = detail;
  const element = document.getElementById(`direct-upload-${id}`);
  if (!element) return;

  element.classList.add("direct-upload--complete");
});

ActiveStorage.start();
