import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["labSelect", "labTests", "labTestRow"];
  declare labSelectTarget: HTMLSelectElement;
  declare labTestsTarget: HTMLElement;
  declare labTestRowTargets: HTMLElement[];

  connect() {
    // If a lab is selected, only show checkboxes for that lab.
    this.displayLabTestsForSelectedLab(this.labSelectTarget.value);

    // Don't run checkDefaultInUiLabTests on connect because this will override
    // existing choices when editing a project. For a new project,
    // no lab is selected by default, so there are no boxes to check.
  }

  selectLab(event: Event) {
    const target = event.target as HTMLSelectElement;
    const selectedLab = target.value;

    this.displayLabTestsForSelectedLab(selectedLab);
    this.checkDefaultInUiLabTests();
  }

  displayLabTestsForSelectedLab(selectedLab: string) {
    if (this.labTestRowTargets.length === 0) return;

    this.labTestRowTargets.forEach((row) => {
      const checkbox = row.querySelector(
        "input[type=checkbox]"
      ) as HTMLInputElement;
      if (row.dataset.destination === selectedLab) {
        row.classList.remove("hidden");
      } else {
        row.classList.add("hidden");
      }
    });

    if (selectedLab) {
      this.showLabTests();
    } else {
      this.hideLabTests();
    }
  }

  // Of the visible checkboxes, check those marked default in UI
  checkDefaultInUiLabTests() {
    if (this.labTestRowTargets.length === 0) return;

    this.labTestRowTargets.forEach((row) => {
      const checkbox = row.querySelector(
        "input[type=checkbox]"
      ) as HTMLInputElement;
      if (!row.classList.contains("hidden")) {
        if (row.dataset.defaultInUi === "true") {
          checkbox.checked = true;
        }
      } else {
        checkbox.checked = false;
      }
    });
  }

  showLabTests() {
    this.labTestsTarget.classList.remove("hidden");
  }

  hideLabTests() {
    this.labTestsTarget.classList.add("hidden");
  }
}
