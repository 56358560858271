import * as Rails from "@rails/ujs";

export function restoreRailsDisableWith() {
  // restores the Rails ujs behaviors for "data-disable-with"

  Rails.delegate(
    document,
    Rails.linkDisableSelector,
    "turbo:before-cache",
    Rails.enableElement
  );
  Rails.delegate(
    document,
    Rails.buttonDisableSelector,
    "turbo:before-cache",
    Rails.enableElement
  );
  Rails.delegate(
    document,
    Rails.buttonDisableSelector,
    "turbo:submit-end",
    Rails.enableElement
  );

  Rails.delegate(
    document,
    Rails.formSubmitSelector,
    "turbo:submit-start",
    Rails.disableElement
  );
  Rails.delegate(
    document,
    Rails.formSubmitSelector,
    "turbo:submit-end",
    Rails.enableElement
  );
  Rails.delegate(
    document,
    Rails.formSubmitSelector,
    "turbo:before-cache",
    Rails.enableElement
  );
}
