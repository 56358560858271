import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "processButton",
    "subSampleCheckbox",
    "selectedSubSamplesTableBody",
    "selectedSubSamplesTotalVolume",
    "derivedSubSamplesTableBody",
  ];
  declare processButtonTarget: HTMLButtonElement;
  declare subSampleCheckboxTargets: HTMLInputElement[];
  declare selectedSubSamplesTableBodyTarget: HTMLElement;
  declare selectedSubSamplesTotalVolumeTarget: HTMLElement;
  declare derivedSubSamplesTableBodyTarget: HTMLElement;
  declare derivedSubSampleTemplate: Node;

  connect() {
    this.derivedSubSampleTemplate = this.derivedSubSamplesTableBodyTarget
      .querySelector("tbody tr:last-child")!
      .cloneNode(true);

    this.subSampleSelectionUpdated();
  }

  openDrawer() {
    this.dispatch("openDrawer");
  }

  subSampleSelectionUpdated() {
    const selectedSubSamples = this.subSampleCheckboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.closest("tr"))
      .filter(Boolean)
      .map((row) => {
        const cells = row!.querySelectorAll("td");
        // Extract barcode and volume
        return [cells[1].innerText, cells[6].innerText];
      });

    this.selectedSubSamplesTableBodyTarget.innerHTML = selectedSubSamples
      .map(
        ([barcode, volume]) =>
          `<tr><td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0">${barcode}</td><td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${volume}</td></tr>`
      )
      .join("");

    if (selectedSubSamples.length > 0) {
      const totalVolume = selectedSubSamples
        .map(([_, volume]) => parseFloat(volume) || 0)
        .reduce((a, b) => a + b);

      this.selectedSubSamplesTotalVolumeTarget.innerHTML =
        new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
          totalVolume
        );

      this.processButtonTarget.disabled = false;
    } else {
      this.processButtonTarget.disabled = true;
    }
  }

  addDerivedSubSample() {
    const newRow = this.derivedSubSampleTemplate.cloneNode(true);
    this.derivedSubSamplesTableBodyTarget.appendChild(newRow);
  }
}
