import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["drawer"];
  declare drawerTarget: HTMLElement;

  open() {
    this.element.classList.remove("hidden");

    setTimeout(() => {
      this.drawerTarget.classList.replace("translate-x-full", "translate-x-0");
    }, 0);
  }

  close() {
    this.drawerTarget.classList.replace("translate-x-0", "translate-x-full");
  }

  isClosed() {
    return this.drawerTarget.classList.contains("translate-x-full");
  }

  toggled() {
    if (this.isClosed()) {
      this.element.classList.add("hidden");
    }
  }
}
