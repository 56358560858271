import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label"];
  declare labelTarget: HTMLElement;

  showFileName(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      let displayNames = [];
      for (let i = 0; i < inputElement.files.length; i++) {
        const file = inputElement.files[i];
        displayNames.push(file.name);
      }
      this.labelTarget.innerText = displayNames.join(", ");
    } else {
      this.labelTarget.innerText = "Upload a file";
    }
  }
}
