import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["selectAll", "selectSamplePlan", "compareButton"];
  declare selectAllTarget: HTMLInputElement;
  declare hasSelectAllTarget: boolean;
  declare selectSamplePlanTargets: HTMLInputElement[];
  declare compareButtonTarget: HTMLButtonElement;
  declare compareButtonDisabledText: string;

  compareButtonTargetConnected() {
    this.compareButtonDisabledText = this.compareButtonTarget.innerText;
  }

  selectAll(event: Event) {
    const target = event.target as HTMLInputElement;
    this.selectSamplePlanTargets.forEach((checkbox) => {
      checkbox.checked = !checkbox.disabled && target.checked;
    });
    this.updateCompareButtonState();
  }

  updateCompareButtonState() {
    const selectedSamplePlans = this.selectSamplePlanTargets.filter(
      (checkbox) => checkbox.checked
    );

    if (selectedSamplePlans.length >= 2) {
      this.compareButtonTarget.disabled = false;
      this.compareButtonTarget.innerText = "Compare selected sample plans";
    } else {
      this.compareButtonTarget.disabled = true;
      this.compareButtonTarget.innerText = this.compareButtonDisabledText;
    }
  }

  updateSelectAllCheckboxState() {
    if (this.hasSelectAllTarget) {
      this.selectAllTarget.checked = this.selectSamplePlanTargets
        .filter((checkbox) => !checkbox.disabled)
        .every((checkbox) => checkbox.checked);
    }
  }
}
