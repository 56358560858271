import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["overlay", "revealButton"];
  declare overlayTarget: HTMLElement;
  declare revealButtonTarget: HTMLElement;
  declare hasRevealButtonTarget: boolean;

  revealOverlay(e: Event) {
    this.overlayTarget.style.display = "block";
    if (this.hasRevealButtonTarget) {
      this.revealButtonTarget.style.display = "none";
    }
  }

  dismissOverlay(e: Event) {
    this.overlayTarget.style.display = "none";
    if (this.hasRevealButtonTarget) {
      this.revealButtonTarget.style.display = "inline";
    }
  }
}
