import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "marginOfErrorField",
    "targetNumberOfSitesField",
    "previousStratificationParams",
    "previousStratificationParamsSelect",
    "confidenceIntervalInput",
    "marginOfErrorInput",
    "targetNumberOfSitesInput",
    "maxDepthInput",
    "minSampleSitesPerStratumInput",
    "fieldBoundaryBufferInput",
  ];
  declare marginOfErrorFieldTarget: HTMLElement;
  declare targetNumberOfSitesFieldTarget: HTMLElement;
  declare previousStratificationParamsTarget: HTMLElement;
  declare hasPreviousStratificationParamsTarget: boolean;
  declare previousStratificationParamsSelectTarget: HTMLSelectElement;
  declare confidenceIntervalInputTarget: HTMLInputElement;
  declare marginOfErrorInputTarget: HTMLInputElement;
  declare targetNumberOfSitesInputTarget: HTMLInputElement;
  declare maxDepthInputTarget: HTMLSelectElement;
  declare minSampleSitesPerStratumInputTarget: HTMLInputElement;
  declare fieldBoundaryBufferInputTarget: HTMLInputElement;

  connect() {
    if (
      this.hasPreviousStratificationParamsTarget &&
      !this.previousStratificationParamsTarget.classList.contains("hidden")
    ) {
      this.selectPreviousStratificationParams();
    }
  }

  showMarginOfErrorField() {
    this.targetNumberOfSitesFieldTarget.classList.add("hidden");
    this.targetNumberOfSitesInputTarget.disabled = true;
    this.marginOfErrorFieldTarget.classList.remove("hidden");
    this.marginOfErrorInputTarget.disabled = false;
  }

  showTargetNumberOfSitesField() {
    this.marginOfErrorFieldTarget.classList.add("hidden");
    this.marginOfErrorInputTarget.disabled = true;
    this.targetNumberOfSitesFieldTarget.classList.remove("hidden");
    this.targetNumberOfSitesInputTarget.disabled = false;
  }

  showPreviousStratificationParams() {
    if (this.hasPreviousStratificationParamsTarget) {
      this.previousStratificationParamsTarget.classList.remove("hidden");
    }
  }

  hidePreviousStratificationParams() {
    if (this.hasPreviousStratificationParamsTarget) {
      this.previousStratificationParamsTarget.classList.add("hidden");
    }
  }

  selectPreviousStratificationParams() {
    const [
      confidenceInterval,
      marginOfError,
      maxDepth,
      minSampleSitesPerStratum,
      fieldBoundaryBuffer,
    ] = this.previousStratificationParamsSelectTarget.value.split("-");

    this.confidenceIntervalInputTarget.value = confidenceInterval;
    this.marginOfErrorInputTarget.value = marginOfError;
    this.maxDepthInputTarget.value = maxDepth;
    this.minSampleSitesPerStratumInputTarget.value = minSampleSitesPerStratum;
    this.fieldBoundaryBufferInputTarget.value = fieldBoundaryBuffer;
  }
}
