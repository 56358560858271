import { Controller } from "@hotwired/stimulus";

type SampleType = {
  depth: number;
};

type SlicingPattern = {
  slices: [number, number][];
};

export default class extends Controller {
  static targets = ["sampleTypeSelect", "slicingPatternSelect"];
  declare sampleTypeSelectTarget: HTMLSelectElement;
  declare slicingPatternSelectTarget: HTMLSelectElement;

  connect() {
    this.updateSlicingPatternOptions();
  }

  static values = {
    sampleTypes: Object,
    slicingPatterns: Object,
  };
  declare sampleTypesValue: { [key: string]: SampleType };
  declare slicingPatternsValue: { [key: string]: SlicingPattern };

  handleSelectNewSampleType() {
    this.deselectSlicingPattern();
    this.updateSlicingPatternOptions();
  }

  updateSlicingPatternOptions() {
    const { value } = this.sampleTypeSelectTarget;
    let sampleType: SampleType | null = null;

    if (value) {
      sampleType = this.sampleTypesValue[value];
    }

    this.disableInvalidSlicingPatterns(sampleType);
  }

  deselectSlicingPattern() {
    this.slicingPatternSelectTarget.selectedIndex = 0;
  }

  disableInvalidSlicingPatterns(sampleType: SampleType | null) {
    const validOptions = new Set(this.validSlicingPatterns(sampleType));

    Array.from(this.slicingPatternSelectTarget.options).forEach((option) => {
      option.disabled = !validOptions.has(option.value);
    });
  }

  validSlicingPatterns(sampleType: SampleType | null): string[] {
    const validOptions: string[] = [""];

    if (sampleType == null) {
      return validOptions;
    }

    Object.entries(this.slicingPatternsValue).forEach(
      ([key, slicingPattern]) => {
        const [startDepth, endDepth] =
          slicingPattern.slices[slicingPattern.slices.length - 1];

        if (endDepth <= sampleType.depth) {
          validOptions.push(key);
        }
      }
    );

    return validOptions;
  }
}
