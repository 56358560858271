import * as Sentry from "@sentry/browser";

// The Response object we get back from fetch has a built-in .json() method for
// reading the response body and parsing it as JSON in a single step:
//
//     const response = await fetch(url, { headers: { accept: "application/json" } });
//     const json = await response.json();
//
// This is handy, but if the response isn't valid JSON, it blows up and all we
// get is a cryptic sentry error like
// https://sentry.io/organizations/yard-stick-pbd/issues/2687348114/.
//
// This helper attempts to provide more meaningful errors by logging the entire
// response to sentry if:
// - The response is not a 2XX success response
// - The response body is empty, or we failed to read it
// - The response body is not valid JSON
//
// Resolves to null if any of the above happen.
export async function tryToParseResponseJson<ResponseType>(
  response: Response
): Promise<ResponseType | null> {
  let body = null;
  let error: Error | null = null;
  try {
    body = await response.text();
  } catch (readError) {
    error = readError as Error;
  }

  if (!response.ok || !body || error) {
    logJsonResponseError(response, body, error);
    return null;
  }

  try {
    return JSON.parse(body);
  } catch (parseError) {
    logJsonResponseError(response, body, parseError as Error);
    return null;
  }
}

function logJsonResponseError(
  response: Response,
  body: string | null,
  error: Error | null
) {
  Sentry.captureException(error || "Failed to fetch JSON", {
    contexts: {
      response: {
        url: response.url,
        status: response.status,
        headers: Object.fromEntries(response.headers.entries()),
        body,
      },
    },
  });
}
