import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit"];
  declare submitTarget: HTMLElement;

  revealButton(e: Event) {
    this.submitTarget.classList.remove("hidden");
  }
}
