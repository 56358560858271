import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "menu"];
  declare menuTarget: HTMLElement;
  declare buttonTarget: HTMLElement;

  toggleMenu(event: MouseEvent) {
    event.preventDefault();

    if (this.buttonTarget.getAttribute("aria-expanded") == "false") {
      this.show();
    } else {
      this.hide();
    }
    event.stopPropagation();
  }

  show() {
    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.menuTarget.classList.remove("hidden");
  }

  hide() {
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.menuTarget.classList.add("hidden");
  }
}
