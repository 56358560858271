import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["togglable"];
  declare togglableTarget: HTMLElement;

  show(_e: Event) {
    this.togglableTarget.classList.remove("hidden");
  }

  hide(_e: Event) {
    this.togglableTarget.classList.add("hidden");
  }

  toggle(_e: Event) {
    this.togglableTarget.classList.toggle("hidden");
  }
}
